// firebaseConfig.js

const firebaseConfig = {
  apiKey: "AIzaSyDSea1haXzrcGgnMq59PdscTCCska0bbvQ",
  authDomain: "opicscript-bb15b.firebaseapp.com",
  projectId: "opicscript-bb15b",
  storageBucket: "opicscript-bb15b.appspot.com",
  messagingSenderId: "164474153010",
  appId: "1:164474153010:web:63fed426cfcc439302f42b",
  measurementId: "G-CDEK53YQZQ",
};

export default firebaseConfig;
